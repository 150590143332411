// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import "bootstrap"
import "../stylesheets/application"
import "../stylesheets/home"
import "@fortawesome/fontawesome-free/js/all"
import 'trix/dist/trix.css'
import './bootstrap_custom.js'
import './introjs.js'

//import 'datatables.net-bs4/css/dataTables.bootstrap4.css'




require("@rails/ujs").start()
    //require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("jquery")
require("moment/locale/ja")
require("tempusdominus-bootstrap-4")
require("trix")
require("@rails/actiontext")
require("jquery-mask-plugin")


//import $ from 'jquery';
//global.$ = jQuery;

global.toastr = require("toastr");
$.jMaskGlobals.watchDataMask = true;





//window.$ = $

//require('imports-loader?define=>false!datatables.net')(window, $)
//require('imports-loader?define=>false!datatables.net-dt')(window, $)
//require('imports-loader?define=>false!datatables.net-bs4')(window, $)



// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
const images = require.context('../images', true);
const imagePath = (name) => images(name, true);